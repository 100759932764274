import React, { useEffect } from "react";
import Helmet from "react-helmet";

const Comments = ({ id, sectionName }) => {
  const initCactus = () => {
    if (window.initComments) {
      window.initComments({
        node: document.getElementById(id),
        defaultHomeserverUrl: "https://matrix.cactus.chat:8448",
        serverName: "cactus.chat",
        siteName: "mqnxs",
        commentSectionId: sectionName,
      });
    } else {
      setTimeout(initCactus, 100);
    }
  };

  useEffect(() => {
    initCactus();
  }, [initCactus]);
  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src="https://latest.cactus.chat/cactus.js"
        />
        <link
          rel="stylesheet"
          href="https://latest.cactus.chat/style.css"
          type="text/css"
        />
      </Helmet>
      <div id={id} />
    </>
  );
};

export default Comments;
