import React from "react";
import Helmet from "react-helmet";
import Layout from "../components/Layout";
import styles from "../styles/tags.module.scss";
import Comments from "../components/Comments";

const Chat = () => {
  return (
    <>
      <Helmet title="Chat" />
      <Layout header="Chat">
        <div className={styles.container}>
          <Comments id="id" sectionName="comments" />
        </div>
      </Layout>
    </>
  );
};

export default Chat;
